














import {Component} from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {mixins} from 'vue-class-component';

@Component
export default class ResetPasswordView extends mixins(ErrorMessageHandlerMixin) {
}
